﻿export default class QuantityValidator {
    /**
     * Calculates the correct quantity and order unit.
     * @param {object} input: The input data.
     *      requestedQuantity: The requested quantity.
     *      requestedUnitsPerPack: The requested units per pack.
     *      previousQuantity: The previously entered quantity.
     *      totalQuantity: The total quantity (requested quantity * requested units per pack).
     *      minimumOrderQuantity: The minimum order quantity.
     *      maximumOrderQuantity: The maximum order quantity.
     *      incrementOrderQuantity: The incremental order quantity.
     *      restOrderQuantity: The rest order quantity.
     *      roundType: The rounding type.
     *      roundUnitsPerPack: The rounding units per pack.
     *      roundOrderUnitId: The rounding order unit.
     * @param {boolean} isChangeOrderUnit: If order unit was changed.
     */
    static validate(input: any, isChangeOrderUnit: boolean) {
        if (input.minimumOrderQuantity < 1) {
            input.minimumOrderQuantity = 1;
        }

        if (input.maximumOrderQuantity < 1) {
            input.maximumOrderQuantity = 10_000_000 / input.requestedUnitsPerPack;
        }

        if (input.incrementOrderQuantity < 1) {
            input.incrementOrderQuantity = 1;
        }

        const data = {
            isRounded: false,
            correctQuantity: 0,
            correctOrderUnitId: 0
        };

        // set correct quantity
        if (isNaN(parseFloat(input.requestedQuantity))) {
            data.correctQuantity = input.minimumOrderQuantity;
        } else {
            if (isChangeOrderUnit) {
                data.correctQuantity = QuantityValidator.validateByOrderUnit(input);
            } else {
                data.correctQuantity = QuantityValidator.validateByQuantity(input);
            }
        }

        // set rounding
        if (input.roundType === "YG00" || input.roundType === "YG02") {
            input.requestedQuantity = data.correctQuantity;

            const roundResult = QuantityValidator.round(input);

            if (roundResult !== null) {
                data.isRounded = true;
                data.correctQuantity = roundResult.correctQuantity;
                data.correctOrderUnitId = roundResult.correctOrderUnitId;
            }
        }

        return data;
    }

    static validateByQuantity(input: any): number {
        // validate minimum is at least REST or MOQ
        let minimum = 0;

        if (input.restOrderQuantity !== undefined) {
            minimum = Math.max(input.restOrderQuantity > 0 ? input.restOrderQuantity : input.minimumOrderQuantity, 1);
        } else {
            minimum = Math.max(input.minimumOrderQuantity, 1);
        }

        if (input.requestedQuantity < minimum || input.requestedQuantity > input.maximumOrderQuantity) {
            return minimum;
        }

        // check if quantity is INCREASED or DECREASED to find nearest valid quantity
        ////var isIncrease = input.previousQuantity === 0 || input.requestedQuantity >= input.previousQuantity;
        const isIncrease = true; // PGB: always go for higher incremental value

        const ioqAmount = input.requestedQuantity - input.minimumOrderQuantity;
        const ioqRemainder = isIncrease
            ? Math.ceil(ioqAmount / input.incrementOrderQuantity)
            : Math.floor(ioqAmount / input.incrementOrderQuantity);

        let calculated = input.minimumOrderQuantity + ioqRemainder * input.incrementOrderQuantity;
        if (input.restOrderQuantity) {
            if (isIncrease) {
                const calculatedWithRestUp =
                    input.minimumOrderQuantity +
                    input.restOrderQuantity +
                    Math.max(ioqRemainder - 1, 0) * input.incrementOrderQuantity;

                if (calculatedWithRestUp >= input.requestedQuantity && calculatedWithRestUp < calculated) {
                    calculated = calculatedWithRestUp;
                }
            } else {
                const calculatedWithRestDown =
                    input.minimumOrderQuantity + input.restOrderQuantity + ioqRemainder * input.incrementOrderQuantity;

                if (calculatedWithRestDown <= input.requestedQuantity && calculatedWithRestDown > calculated) {
                    calculated = calculatedWithRestDown;
                }
            }
        }

        return calculated;
    }

    static validateByOrderUnit(input: any): number {
        const requestedMultiplier = parseFloat(input.totalQuantity) / parseFloat(input.requestedUnitsPerPack);

        if (requestedMultiplier > 1) {
            return Math.ceil(requestedMultiplier);
        }

        return input.minimumOrderQuantity;
    }

    static round(input: any) {
        // when rounding type = YG00, 80% of KAR is taken
        // when rounding type = YG02, 80% of BOX is taken
        const requestedValue = input.requestedQuantity * input.requestedUnitsPerPack / input.roundUnitsPerPack;

        // if requested value is greater than 80% + less than the round quantity
        if (requestedValue >= 0.8 && requestedValue < 1) {
            return {
                correctQuantity: Math.round(requestedValue),
                correctOrderUnitId: input.roundOrderUnitId
            };
        }

        return null;
    }
}
