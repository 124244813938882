// entry point for general site code / not for vue code
import $ from "jquery";
import $$ from "./scripts/utils/double-dollar";
import { Button, Modal, Tab, Tooltip } from "bootstrap";
import { format, parse } from "date-fns";
import Datepicker from "js-datepicker";
import NatchGtm, { EventNamesSchema } from "natch-gtm4";

////import 'bootbox';
import "toastr";
import "jquery-validation";

import "./scripts/http/set-axios-defaults";

import CustomHelper from "./scripts/helpers/custom-helper";

import CustomSwiper from "./scripts/components/custom-swiper";
import MiniBasket from "./scripts/components/mini-basket";
import PwaHelper from "./scripts/components/pwa-helper";
import QuickScan from "./scripts/components/quick-scan";
import SearchScan from "./scripts/components/search-scan";
import SearchSuggestions from "./scripts/components/search-suggestions";

import Account from "./scripts/pages/account";
import Software from "./scripts/pages/software";
import Products from "./scripts/pages/products";
import Upload from "./scripts/pages/upload";
import Terminal from "./scripts/pages/terminal";
import BasketStep1 from "./scripts/pages/basket-step-1";
import BasketStep2 from "./scripts/pages/basket-step-2";
import BasketStep3 from "./scripts/pages/basket-step-3";
import Templates from "./scripts/pages/templates";

import VueComponentLoader from "./scripts/vue-component-loader";

/* this declaration in /clientapp/types/static.d.ts isn't picked up, so putting it here */
declare global {
    interface Window {
        $: JQueryStatic;
        jQuery: JQueryStatic;
    }
}

// need jQuery on the window object for jquery validation
window.jQuery = $;

class App {
    static init() {
        App.initErrorHandler();

        // will process product list, product detail, checkout, custom events and custom clicks
        const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
        natchGtm.processPage();

        $(".d-none.onload").hide().removeClass("d-none").removeClass("onload");

        // initialize Bootstrap components
        $$(`[data-bs-toggle="button"]`, el => new Button(el));
        $$(`[rel="tooltip"]`, el => new Tooltip(el));
        $$(`[data-bs-toggle="tooltip"]`, el => new Tooltip(el));

        // other
        App.initAutoLink();
        App.initDatePicker();
        App.initDropDownToggle();
        App.initForms();
        App.initHeader();
        App.initInputFocus();
        App.initModals();
        App.initNewsLetter();
        App.initScrollToTop();
        App.initSearchSuggestions();
        App.initTabs();
        App.initToaster();

        MiniBasket.load();

        // components
        CustomSwiper.init();
        PwaHelper.init();
        QuickScan.init();
        SearchScan.init();

        // pages
        Account.init();
        Software.init();
        Products.init();
        Upload.init();
        Terminal.init();
        BasketStep1.init();
        BasketStep2.init();
        BasketStep3.init();
        Templates.init();

        // vue components (loader)
        VueComponentLoader.load();
    }

    static initBlockedDelivery(isBlocked: boolean, message: string) {
        if (!isBlocked) {
            return;
        }

        // @ts-ignore
        bootbox.alert(message);
    }

    private static initAutoLink() {
        $(".js-auto-link").each(function(idx, el) {
            const $link = $(this).find("a");

            if ($link.length !== 0) {
                $(el)
                    .click(function() {
                        if ($link.attr("target") === "_blank") {
                            window.open($link.attr("href"));
                        } else {
                            document.location.href = $link.attr("href") as string;
                        }
                    })
                    .css("cursor", "pointer");
            }
        });
    }

    private static initDatePicker() {
        // https://www.npmjs.com/package/js-datepicker
        const options = {
            startDay: 1,
            disableYearOverlay: true,
            formatter: (input: any, date: Date, instance: any) => {
                input.value = format(date, "dd/MM/yyyy");
            },
            onSelect: (instance: any, date: Date) => {

            },
        };

        $$(`[rel="datepicker"]`, el => {
            const datePickerId = $(el).prop("id");
            const datePickerInstance = Datepicker(`#${datePickerId}`, options);

            const dateValue = $(el).val() as string;
            const parsedDateValue = parse(dateValue, "dd/MM/yyyy", new Date());
            datePickerInstance.setDate(parsedDateValue, true);

            const $datePickerIcon = $(el).closest(".input-group").find(`[type="button"]`);
            $datePickerIcon.on("click", (e: JQueryEventObject) => {
                e.stopPropagation();
                datePickerInstance.show();
            });
        });
    }

    private static initDropDownToggle() {
        $("body").on("click", ".js-dropdown-submenu-toggle", (e) => {
            e.preventDefault();
            document.location.href = $(e.currentTarget).prop("href");
        });
    }

    private static initErrorHandler() {
        const maxErrorThreshold = 3;
        let errorThreshold = 0;

        window.onerror = function(message, source, lineno, columnno, error) {
            if (errorThreshold++ > maxErrorThreshold) {
                return false;
            }

            // exception occurs when the quick scan modal is toggled, presumably from the scanner component
            if (source?.includes("quickscan") && message === "ResizeObserver loop completed with undelivered notifications.") {
                return false;
            }

            const arg: any = {
                message: message,
                url: source,
                line: lineno,
                column: columnno,
                errorObject: null
            };

            if (error) {
                arg.errorObject = JSON.stringify(error);
            }

            console.info("Sending error to server");
            console.info(arg);

            const xhr = new XMLHttpRequest();
            xhr.open("POST", "/javascript/logerror");
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.send(JSON.stringify(arg));

            return false;
        };
    }

    private static initForms() {
        const $form = $("form");

        $form.find(".js-toggle-submit").each(function () {
            const $this = $(this);
            CustomHelper.setToggleSubmit($this.parents("form"), $this);
        });

        $form.on("click", ".js-toggle-submit", function () {
            const $this = $(this);
            CustomHelper.setToggleSubmit($this.parents("form"), $this);
        });

        $(".js-auto-submit").on("click", function () {
            $(this).parents("form").submit();
        });
    }

    private static initHeader() {
        $("body").on("click", ".js-category-nav-activator", function() {
            const $categoryNavActivator = $(this);

            const $languageMenuFixed = $(".js-language-menu-fixed");
            if ($languageMenuFixed.is(":visible")) {
                $categoryNavActivator.removeClass("activated");
                $languageMenuFixed.addClass("d-none");
            } else {
                $categoryNavActivator.addClass("activated");
                $languageMenuFixed.removeClass("d-none");
            }
        });
    }

    private static initInputFocus() {
        $(".js-input-focus").trigger("focus");
    }

    private static initModals(){
        let modalList = document.querySelectorAll(".modal");
        for (let i = 0; i < modalList.length; i++){
            const el = modalList[i] as HTMLElement;
            const modal = new Modal(el);

            if (el.dataset.ntShowOnLoad === "true"){
                modal.show();
            }
        }

        const modalNewsMessages = document.querySelector("#modal-news-messages") as HTMLElement;

        if (modalNewsMessages) {
            modalNewsMessages.addEventListener("hide.bs.modal", (e) => {
                const hideList = $(document.querySelector("#modal-news-messages-hide-list") as HTMLElement).val();
                document.cookie = `nt_news-messages=${hideList};expires=${new Date(2050, 0, 1).toUTCString()}; path=/; domain=${window.location.hostname}; SameSite=lax; Secure`;
            });
        }
    }

    private static initNewsLetter(){
        $("#form-newsletter-footer").on("submit", (e) => {
            e.preventDefault();

            const $this = $(e.currentTarget);
            const $form = $this.closest("form");

            // @ts-ignore
            $form.validate();

            // @ts-ignore
            if ($form.valid()){
                const newsLetterModalElement = document.getElementById("modal-dialog-newsletter") as HTMLElement;
                const newsLetterModal = new Modal(newsLetterModalElement);
                newsLetterModal.show();

                const emailAddressValue = $("#newsletter-footer-email").val() as string;
                $("#newsletter-email").val(emailAddressValue);
            }
        })

        $("#form-newsletter").on("change", ".js-newsletter-usertype", (e) => {
            const $this = $(e.currentTarget);
            const $form = $this.closest("form");

            const $sector = $form.find(".js-newsletter-sector");
            $sector.removeClass("d-none");

            const $sectorSelect = $sector.find("select");
            $sectorSelect.addClass("d-none");
            $sectorSelect.prop("disabled", true);

            const $target = $form.find($this.data("target"));
            $target.removeClass("d-none");
            $target.prop("disabled", false);
        });
    }

    private static initScrollToTop() {
        $(window).on("scroll",(e) => {
            if ($(e.currentTarget)?.scrollTop() ?? 0 > 300) {
                $(".nt-move-to-top").fadeIn(200);
            } else {
                $(".nt-move-to-top").fadeOut(200);
            }
        });
    }

    private static initSearchSuggestions() {
        $$(".js-search-suggestions-autocomplete", (el) => new SearchSuggestions(el));
    }

    private static initTabs() {
        $$(`[rel="tab"]`, el => {
            const tabTrigger = new Tab(el);

            el.addEventListener('click', function (event) {
                event.preventDefault()
                tabTrigger.show()
            })
        });
    }

    private static initToaster() {
        // @ts-ignore
        if (typeof $.fn.toastr === "function") {
            // @ts-ignore
            toastr.options = {
                closeButton: true,
                debug: false,
                newestOnTop: true,
                progressBar: false,
                positionClass: "toast-top-right",
                preventDuplicates: false,
                // @ts-ignore
                onclick: null,
                // @ts-ignore
                showDuration: "3000",
                // @ts-ignore
                hideDuration: "3000",
                // @ts-ignore
                timeOut: "7000",
                // @ts-ignore
                extendedTimeOut: "1000",
                showEasing: "swing",
                hideEasing: "linear",
                showMethod: "fadeIn",
                hideMethod: "fadeOut"
            };
        }
    }
}

App.init();

// @ts-ignore
App.initBlockedDelivery(Global.IsBlockedDelivery, Global.IsBlockedDeliveryMessage);

console.info("🚀 index.ts loaded");
