﻿import 'toastr';
import $ from "jquery";
import BasketHelper from "./basket-helper";
import QuantityValidator from "../validators/quantity-validator";
import DiscountValidator from "../validators/discount-validator";
import Multilang from "../utils/multilang";
import { AddToBasketRequest } from "../models/baskets";
import { ProductPriceDisplayType } from "../models/products";
import { NatchOsDataLayerProduct } from "natch-gtm4";
import "../extensions/number-extensions";

export default class ProductHelper {
    static updateDiscountDisplay($container: any) {
        const $quantity = $container.find("[name=quantity]");
        const $orderUnit = $container.find("[name=orderunitid]").find("option:selected");
        const $unitPrice = $container.find(".js-product-unit-price");
        const $discount = $container.find(".js-product-discount");
        const $discountPercentage = $container.find(".js-product-discount-percentage");
        const $discountUnitPrice = $container.find(".js-product-discount-unit-price");

        const input = {
            requestedQuantity: parseFloat($quantity.val()),
            requestedUnitsPerPack: $orderUnit.data("upp"),
            discountUnitsPerPack: $discount.data("unitsperpack"),
            discountPercentage: $discount.data("percentage")
        };

        const showDiscount = DiscountValidator.showDiscount(input);

        if (showDiscount) {
            $discount.removeClass("d-none");
        } else {
            $discount.addClass("d-none");
        }

        const showDiscountValue = DiscountValidator.showDiscountValue(input);

        if (showDiscountValue) {
            $unitPrice.addClass("text-decoration-line-through");
            $discountPercentage.addClass("d-none");
            $discountUnitPrice.removeClass("d-none");
        } else {
            $unitPrice.removeClass("text-decoration-line-through");
            $discountPercentage.removeClass("d-none");
            $discountUnitPrice.addClass("d-none");
        }
    }

    static updateStockIndicator(productStock) {
        const $availableStockIndicator = $(".js-product-stock-available-" + productStock.productCode);
        const $notAvailableStockIndicator = $(".js-product-stock-not-available-" + productStock.productCode);

        $availableStockIndicator.addClass("d-none");
        $notAvailableStockIndicator.addClass("d-none");

        if (productStock.isInStock) {
            $availableStockIndicator.removeClass("d-none");
        } else {
            $notAvailableStockIndicator.removeClass("d-none");
        }
    }

    static updateQuantityAndPrice(
        event,
        $container,
        isProductPage,
        isOnlyChange,
        isTemplate,
        isChangeOfOrderUnit,
        isAddToBasket
    ) {
        event.preventDefault();

        Multilang.load();

        // get fields
        const $quantityField: any = $container.find("[name=quantity]");
        const $quantityPreviousField: any = $container.find(".js-product-order-box--quantity-previous");
        const $quantityTotalField: any = $container.find(".js-product-order-box--quantity-total");
        const $orderUnitField: any = $container.find("[name=orderunitid]");
        const $unitPriceField: any = $container.find("[name=unitprice]");
        const $priceQuantityField: any = $container.find("[name=pricequantity]");
        const $currencyCodeField: any = $container.find("[name=currencycode]");
        const $isPriceRequestField: any = $container.find("[name=ispricerequest]");
        const $productIdField: any = $container.find("[name=productid]");
        const $productPriceDataList: any = $container.find(".js-product-price-data");

        // get values from fields
        const productId: number = $productIdField.val();
        const isPriceRequest: boolean = $isPriceRequestField.val() === "true";
        const unitPrice: number = parseFloat($unitPriceField.val());
        const priceQuantity: number = parseFloat($priceQuantityField.val());
        const orderSource: string = isTemplate ? "List" : "Manual";

        let currencyCode: string = $currencyCodeField.val();
        let orderUnitId: number = $orderUnitField.find("option:selected").val();
        let unitsPerPack: number = parseFloat($orderUnitField.find("option:selected").data("upp"));

        // difference with js code in basket-step1 is that in product pages we don't have a BasketLineID or BasketID, instead we have a ProductID
        let basketLineId: number = 0;
        let basketId: number = 0;

        if (isProductPage !== 1) {
            basketLineId = $container.find("[name=basketlineid]").val();
            basketId = $container.find("[name=basketid]").val(); // is BasketID (checkout) or TemplateID (templates page)
        }

        // validate the quantity by giving an input object to the QuantityValidator, returning correctQuantity
        const input = {
            requestedQuantity: parseFloat($quantityField.val()),
            requestedUnitsPerPack: unitsPerPack,
            previousQuantity: parseFloat($quantityPreviousField.val()),
            totalQuantity: parseFloat($quantityTotalField.val()),
            minimumOrderQuantity: parseFloat($quantityField.data("moq")) / unitsPerPack,
            maximumOrderQuantity: parseFloat($quantityField.data("maq")),
            incrementOrderQuantity: parseFloat($quantityField.data("ioq")) / unitsPerPack,
            restOrderQuantity: parseFloat($quantityField.data("roq")),
            roundType: $quantityField.data("rnd-type"),
            roundUnitsPerPack: $quantityField.data("rnd-upp"),
            roundOrderUnitId: $quantityField.data("rnd-id"),
            discountUnitsPerPack: 0,
            discountPercentage: 0,
            grossPrice: 0
        };

        // set min/max/step for [type=number]
        if (input.minimumOrderQuantity < 1) {
            input.minimumOrderQuantity = 1;
        }

        if (input.maximumOrderQuantity < 1) {
            input.maximumOrderQuantity = 10_000_000 / unitsPerPack;
        }

        if (input.incrementOrderQuantity < 1) {
            input.incrementOrderQuantity = 1;
        }

        $quantityField.prop("min", input.minimumOrderQuantity);
        $quantityField.prop("max", input.maximumOrderQuantity);
        $quantityField.prop("step", input.incrementOrderQuantity);

        // set correct quantity + rounding
        const validationData: any = QuantityValidator.validate(input, isChangeOfOrderUnit);
        const correctQuantity: number = validationData.correctQuantity;

        if (validationData.isRounded) {
            $orderUnitField.val(validationData.correctOrderUnitId);

            orderUnitId = $orderUnitField.find("option:selected").val();
            unitsPerPack = parseFloat($orderUnitField.find("option:selected").data("upp"));
        }

        let grossUnitPrice: number = unitPrice;
        let netUnitPrice: number = unitPrice;
        let correctPriceQuantity: number = priceQuantity;
        let isPriceRequestForData: boolean = false;

        $productPriceDataList.each(function() {
            // @ts-ignore
            const $productPriceData: any = $(this);
            const unitPrice: number = parseFloat($productPriceData.val() as string);
            const priceQuantity: number = parseFloat($productPriceData.data("pq"));

            let minimumOrderQuantity: number = parseFloat($productPriceData.data("moq")) / unitsPerPack;

            if (minimumOrderQuantity < 1) {
                minimumOrderQuantity = 1;
            }

            // if currency code is empty for base unit price, take one that's filled from other package
            if (currencyCode === "" && $productPriceData.data("crc") !== "") {
                currencyCode = $productPriceData.data("crc");
            }

            // now compare with quantity asked (correct quantity is the updated one) here
            if (minimumOrderQuantity <= correctQuantity) {
                // if other unit price than the one before
                if (grossUnitPrice !== unitPrice) {
                    // is overwritten if higher one fits as well (TA 7.2)
                    grossUnitPrice = unitPrice;
                    correctPriceQuantity = priceQuantity;
                }
            }

            // calculate promotion if applicable
            if (isProductPage !== 1) {
                const $discountField: any = $productPriceData.closest("tr").find(".js-product-discount");

                input.requestedQuantity = correctQuantity;
                input.discountUnitsPerPack = $discountField.data("unitsperpack");
                input.discountPercentage = $discountField.data("percentage");
                input.grossPrice = $discountField.data("grossprice");

                if (typeof input.discountPercentage !== "undefined" && input.discountPercentage !== 0) {
                    netUnitPrice = DiscountValidator.calculateNetPrice(input);
                }
            }

            // make sure, when a price request is true, it isn't overwritten by the next value
            if (!isPriceRequestForData) {
                isPriceRequestForData = $productPriceData.data("ipr") === true;
            }
        });

        // create formatted unit price + price quantity
        const priceDisplayType: ProductPriceDisplayType = $container.find(".js-product-order-box--price-display-type").val();
        const salesUnitName: string = $container.find(".js-product-order-box--sales-unit-name").val();
        const salesUnitQuantity: number = $container.find(".js-product-order-box--sales-unit-quantity").val() as number;
        const unitPriceValue = priceDisplayType === "PerSalesUnit" && correctPriceQuantity > 1 ? (netUnitPrice / correctPriceQuantity * salesUnitQuantity).round(2) : netUnitPrice;

        // @ts-ignore
        let formattedUnitPrice: string = Globalize.format(unitPriceValue, "N2");
        let formattedUnitPricePostfix: string = priceDisplayType === "PerSalesUnit" && correctPriceQuantity > 1 ? `/ ${salesUnitName}` : correctPriceQuantity > 1 ? `/ ${correctPriceQuantity}` : "";

        if (isPriceRequest || isPriceRequestForData) {
            formattedUnitPrice = Multilang.getTranslation("product.pricerequest.label.request", "Prijs op aanvraag");

            $container.find(".js-order-box-product-add").addClass("d-none");
            $container.find(".js-order-box-product-discount").addClass("d-none");
            $container.find(".js-order-box-product-price-request").removeClass("d-none");
        } else {
            $container.find(".js-order-box-product-add").removeClass("d-none");
            $container.find(".js-order-box-product-discount").removeClass("d-none");
            $container.find(".js-order-box-product-price-request").addClass("d-none");
        }

        if (isProductPage === 1) {
            $container.find("#unit-price_" + productId).html(formattedUnitPrice);
            $container.find("#unit-price-postfix_" + productId).html(formattedUnitPricePostfix);

            if (isPriceRequest || isPriceRequestForData) {
                $container.find("#unit-price-currency_" + productId).addClass("d-none");
                $container.find("#unit-price-label_" + productId).addClass("d-none");
                $container.find("#unit-price-postfix_" + productId).addClass("d-none");
            } else {
                $container.find("#unit-price-currency_" + productId).removeClass("d-none");
                $container.find("#unit-price-label_" + productId).removeClass("d-none");
                $container.find("#unit-price-postfix_" + productId).removeClass("d-none");
            }
        } else {
            $container.find("#unit-price_" + basketLineId + "_" + basketId).html(formattedUnitPrice);
            $container.find("#unit-price-postfix_" + basketLineId + "_" + basketId).html(formattedUnitPricePostfix);

            if (isPriceRequest || isPriceRequestForData) {
                $container.find("#unit-price-postfix_" + basketLineId + "_" + basketId).addClass("d-none");
            } else {
                $container.find("#unit-price-postfix_" + basketLineId + "_" + basketId).removeClass("d-none");
            }
        }

        if (isProductPage !== 1) {
            // @ts-ignore
            const totalGrossPriceCalculated: number = Globalize.format(
                correctQuantity * unitsPerPack * grossUnitPrice / correctPriceQuantity,
                "N2"
            );

            // @ts-ignore
            const totalNetPriceCalculated: number = Globalize.format(
                correctQuantity * unitsPerPack * netUnitPrice / correctPriceQuantity,
                "N2"
            );

            // show data
            $container.find("#gross-total_" + basketLineId + "_" + basketId).html(totalGrossPriceCalculated);
            $container.find("#net-total_" + basketLineId + "_" + basketId).html(totalNetPriceCalculated);

            if (isPriceRequest || isPriceRequestForData) {
                $container.find("#gross-total_" + basketLineId + "_" + basketId).addClass("d-none");
                $container.find("#net-total_" + basketLineId + "_" + basketId).addClass("d-none");
            } else {
                $container.find("#gross-total_" + basketLineId + "_" + basketId).removeClass("d-none");
                $container.find("#net-total_" + basketLineId + "_" + basketId).removeClass("d-none");
            }
        }

        // change hidden price field
        $unitPriceField.val(grossUnitPrice);

        // fills DOM with corrected quantity
        $quantityField.val(correctQuantity);
        $quantityPreviousField.val(correctQuantity);
        $quantityTotalField.val(correctQuantity * input.requestedUnitsPerPack);

        const $selectedOrderUnit = $orderUnitField.find("option:selected");
        $quantityField.prop("min", $selectedOrderUnit.data("min"));
        $quantityField.prop("max", $selectedOrderUnit.data("max"));
        $quantityField.prop("step", $selectedOrderUnit.data("step"));
        $quantityField.attr("data-moq", $selectedOrderUnit.data("min"));
        $quantityField.attr("data-maq", $selectedOrderUnit.data("max"));
        $quantityField.attr("data-ioq", $selectedOrderUnit.data("step"));

        // part with messages (eventually to be confirmed) and posts
        if (isOnlyChange === 1 && isProductPage === 1) {
            if (input.requestedQuantity !== correctQuantity) {
                const changeProductQuantityMessage = Multilang.getTranslation("ChangeProductQuantity.message.exception", "Aantal werd automatisch (terug) gezet op {0}.");

                // @ts-ignore
                const warningMessage: string = String.format(changeProductQuantityMessage, correctQuantity);

                // @ts-ignore
                toastr["warning"](warningMessage);
            }

            return;
        }

        // get GTM datalayer data in a safe way
        let dataLayerProduct: NatchOsDataLayerProduct | null = null;

        const $dataLayerCartItem = $container.find("div[data-datalayer-cartitem]");

        if ($dataLayerCartItem.length > 0) {
            dataLayerProduct = $dataLayerCartItem.data("datalayer-cartitem") as NatchOsDataLayerProduct;
        }

        const attributionListId = $quantityField.closest("div[data-datalayer-list-id]").data("datalayer-list-id");
        const attributionListName = $quantityField.closest("div[data-datalayer-list-name]").data("datalayer-list-name");

        // if quantity didn't change, just post order
        if (input.requestedQuantity === correctQuantity) {
            if (isAddToBasket === 1) {
                const data: AddToBasketRequest = {
                    productId: productId,
                    quantity: input.requestedQuantity,
                    unitPrice: grossUnitPrice.toString(),
                    priceQuantity: priceQuantity,
                    orderUnitId: orderUnitId,
                    orderSource: orderSource,
                    attributionListId: attributionListId,
                    attributionListName: attributionListName
                };

                BasketHelper.addToBasket(data, dataLayerProduct);

                return;
            }

            BasketHelper.changeBasketLine(
                basketLineId,
                productId,
                input.requestedQuantity,
                grossUnitPrice,
                orderUnitId,
                orderSource,
                isTemplate,
                basketId
            );

            return;
        }

        // if quantity changed show bootbox to confirm/accept message and post order
        if (isProductPage) {
            const bootboxTitle: string = Multilang.getTranslation("AddToBasket.QuantityChanged.Title", "Aantal gewijzigd");

            // @ts-ignore
            const bootboxMessage: string = String.format(Multilang.getTranslation("AddToBasket.QuantityChanged.Message", "Opgelet, het ingegeven aantal werd gewijzigd naar {0} om te voldoen aan de hoeveelheid waarin dit product verkocht wordt."), correctQuantity);

            // @ts-ignore
            bootbox.confirm({
                size: "small",
                title: bootboxTitle,
                message: bootboxMessage,
                buttons: {
                    cancel: {
                        label: Multilang.getTranslation("AddToBasket.QuantityChanged.Cancel", "Terug"),
                        callback: function() {}
                    },
                    confirm: {
                        label: Multilang.getTranslation("AddToBasket.QuantityChanged.Confirm", "Toevoegen"),
                        callback: function() {}
                    }
                },
                callback: function(result) {
                    if (result) {
                        const data: AddToBasketRequest = {
                            productId: productId,
                            quantity: correctQuantity,
                            unitPrice: grossUnitPrice.toString(),
                            priceQuantity: priceQuantity,
                            orderUnitId: orderUnitId,
                            orderSource: orderSource,
                            attributionListId: attributionListId,
                            attributionListName: attributionListName
                        };

                        BasketHelper.addToBasket(data, dataLayerProduct);
                    }
                }
            });
            return;
        }

        // @ts-ignore
        const warningMessage: string = String.format(Multilang.getTranslation("ChangeProductQuantity.message.exception", "Aantal werd automatisch (terug) gezet op {0}."), correctQuantity);

        // @ts-ignore
        toastr["warning"](warningMessage);

        BasketHelper.changeBasketLine(
            basketLineId,
            productId,
            correctQuantity,
            grossUnitPrice,
            orderUnitId,
            orderSource,
            isTemplate,
            basketId
        );
    }
}
