import $ from "jquery";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import NatchGtm, { EventNamesSchema, NatchOsDataLayerProduct } from "natch-gtm4";
import CustomSwiper from "../components/custom-swiper";
import CustomHelper from "../helpers/custom-helper";
import DataLayerHelper from "../helpers/data-layer-helper";
import ProductHelper from "../helpers/product-helper";
import Multilang from "../utils/multilang";
import eventBus from "../utils/event-bus";

export default class Products {
  private static filterItemClicked = false;

  static init() {
    Products.initOverview();
    Products.initDetail();
    Products.initChangeProduct();
    Products.initPriceRequest();
    Products.initScannerEvents();
    Products.initStockLoad();
  }

  static initOverview() {
    Products.initFilters();
    Products.initFavorites();
    Products.initPageSize();
    Products.initOrderProduct();
    Products.initMobileFilterToggle();
    Products.initMobileNavToggle();

    window.addEventListener("popstate", (e) => {
      console.log("handling popstate for " + document.location);
      Products.loadOverview(document.location.toString(), false);
    });

    $("#ajax-container").on("click", "a.js-ajaxify", (e) => {
      e.preventDefault();

      const url = $(e.target).closest("a").prop("href") as string;
      Products.loadOverview(url, true);

      return true;
    });
  }

  static initDetail() {
    Products.initFavorites();
    Products.initProductNumbers();
    Products.initRelatedProducts();
    Products.initOrderProduct();
    Products.initLogDownload();
  }

  static initFilters() {
    const $body = $("body");

    // erase all filters
    $body.off("click", ".js-erase-filters");
    $body.on("click", ".js-erase-filters", (e) => {
      e.preventDefault();

      $(".js-filter-group-list")
        .find("input[type='checkbox']:checked")
        .prop("checked", false);

      let url = CustomHelper.removeUrlParameter(window.location.href, "filters");
      url = CustomHelper.removeUrlParameter(url, "initFilterPropertyId");

      Products.loadOverview(url, true);
    });

    // close all filter groups
    $body.off("click", ".js-close-filters");
    $body.on("click", ".js-close-filters", (e) => {
      e.preventDefault();

      const $filterGroup = $(".js-filter-group");
      $filterGroup.find("i").removeClass("fa-caret-down").addClass("fa-caret-up");
      $filterGroup.data("nt-is-open", "False");

      $(".js-filter-item-list").addClass("d-none");
      $(".js-open-filters").removeClass("d-none");
      $(".js-close-filters").addClass("d-none");
    });

    // open all filter groups
    $body.off("click", ".js-open-filters");
    $body.on("click", ".js-open-filters", (e) => {
      e.preventDefault();

      const $filterGroup = $(".js-filter-group");
      $filterGroup.find("i").removeClass("fa-caret-up").addClass("fa-caret-down");
      $filterGroup.data("nt-is-open", "True");

      $(".js-filter-item-list").removeClass("d-none");
      $(".js-open-filters").addClass("d-none");
      $(".js-close-filters").removeClass("d-none");
    });

    // toggle filter group
    $body.off("click", ".js-toggle-filter-group");
    $body.on("click", ".js-toggle-filter-group", (e) => {
      e.preventDefault();

      const $filterGroup = $(e.target).closest(".js-filter-group");

      if ($filterGroup.data("nt-is-open") === "True") {
        $filterGroup.find("i").removeClass("fa-caret-down").addClass("fa-caret-up");
        $filterGroup.data("nt-is-open", "False");
        $filterGroup.find(".js-filter-item-list").addClass("d-none");
      } else {
        $filterGroup.find("i").removeClass("fa-caret-up").addClass("fa-caret-down");
        $filterGroup.data("nt-is-open", "True");
        $filterGroup.find(".js-filter-item-list").removeClass("d-none");
      }
    });

    // show more filter items
    $body.off("click", ".js-show-more");
    $body.on("click", ".js-show-more", (e) => {
      e.preventDefault();

      const $this = $(e.target);

      $this.closest(".js-filter-item-list").find(".js-filter-item").removeClass("d-none");
      $this.closest(".js-filter-group").data("nt-show-more-link", "False");
      $this.addClass("d-none");
    });

    // check filter item
    $body.off("change", ".js-filter-item input:checkbox");
    $body.on("change", ".js-filter-item input:checkbox", (e) => {
      if (Products.filterItemClicked) {
        e.preventDefault();
        return false;
      }

      Products.filterItemClicked = true;

      const $filterItem = $(e.target).closest(".js-filter-item");

      const open: number[] = [];
      const more: number[] = [];
      const checkedFilterGroupIds: number[] = [];

      const $filterGroups = $filterItem.closest(".js-filter-group-list").find(".js-filter-group");
      $filterGroups.each((i: number, el: HTMLElement) => {
        const $filterGroup = $(el);
        const filterGroupId = $filterGroup.data("id");

        if ($filterGroup.data("nt-is-open") === "True") {
          open.push(filterGroupId);
        }

        if ($filterGroup.data("nt-show-more-link") === "True") {
          more.push(filterGroupId);
        }
      });

      // store checked filter group IDs
      const $checkedFilters = $(".js-filter-item input:checked");
      $checkedFilters.each((i: number, el: HTMLElement) => {
        const $filterGroup = $(el).closest(".js-filter-group");

        const filterGroupId = $filterGroup.data("id");
        if (!checkedFilterGroupIds.includes(filterGroupId)) {
          checkedFilterGroupIds.push(parseInt(filterGroupId));
        }
      });

      const $changeFilterGroup = $(e.target).closest(".js-filter-group");
      let initStateFilterPropertyId = $changeFilterGroup.data("id");

      const changedFilterPropertyType = $changeFilterGroup.data("property-filter-type");
      if (changedFilterPropertyType !== 2) {
        // only if filter type = 2 (Union/OR)
        initStateFilterPropertyId = 0;
      }

      // also, if this was the last prop value in a filter that was unchecked, and there are no more checks in this property, we want the full list again
      const checkedCount = $(":checked", $changeFilterGroup).length;
      if (checkedCount === 0) {
        initStateFilterPropertyId = 0;

        // if there is one group left with checks, use that property
        if (checkedFilterGroupIds.length === 1) {
          initStateFilterPropertyId = checkedFilterGroupIds[0];
        }
      }

      // filterUrl always has '?', and may contain a query
      let filterUrl: string = $filterItem.data("url");
      if (!filterUrl.endsWith("?")) {
        filterUrl += "&";
      }

      const url = `${filterUrl}open=${open.join()}&more=${more.join()}&initFilterPropertyId=${initStateFilterPropertyId}`;
      Products.loadOverview(url, true);
    });
  }

  static initFavorites() {
    const $body = $("body");

    $body.off("click", ".js-favorite");
    $body.on("click", ".js-favorite", (e) => {
      const $favorite = $(e.currentTarget);
      const $dataLayerListItem = $favorite.closest("div[data-datalayer-list-item]");
      const $dataLayerDetail = $favorite.closest("div[data-datalayer-detail]");

      let dataLayerProduct: NatchOsDataLayerProduct | null = null;

      if ($dataLayerListItem.length > 0) {
        dataLayerProduct = $dataLayerListItem.data("datalayer-list-item") as NatchOsDataLayerProduct;
      } else if ($dataLayerDetail.length > 0) {
        dataLayerProduct = $dataLayerListItem.data("datalayer-detail") as NatchOsDataLayerProduct;
      }

      Products.postFavorite($favorite.data("id"), $favorite.hasClass("far"), dataLayerProduct);
      $favorite.toggleClass("fa far");
    });
  }

  static initPageSize() {
    const $body = $("body");

    $body.off("change", ".js-page-size");
    $body.on("change", ".js-page-size", (e) => {
      const url = $(e.target).find("option:selected").data("url");
      Products.loadOverview(url, true);
    });
  }

  static initProductNumbers() {
    $(".js-product-number-change").on("change", (e) => {
      const $productNumber = $(e.currentTarget);
      Products.postProductNumber($productNumber.data("id"), $productNumber.val() as string);
    });
  }

  static initChangeProduct() {
    const isProductPage = 1;
    const isOnlyChange = 1;
    const isTemplate = 0;
    const isAddToBasket = 0;

    let $container = $("#product-overview");
    if ($container.length === 0) {
      $container = $("#product-detail");
    }

    const $form = $container.find(".js-order-product");

    $form.off("change", "[name=quantity]");
    $form.on("change", "[name=quantity]", (e) => {
      const $container = $(e.target).closest(".js-product-order-box");

      ProductHelper.updateQuantityAndPrice(
        e,
        $container,
        isProductPage,
        isOnlyChange,
        isTemplate,
        0,
        isAddToBasket
      );

      ProductHelper.updateDiscountDisplay($container);
    });

    $form.off("change", "[name=orderunitid]");
    $form.on("change", "[name=orderunitid]", (e) => {
      const $this = $(e.target);

      const eanCode = $this.find("option:selected").data("ean");
      $("span.js-product-ean").text(eanCode);
      $("input.js-product-ean").val(eanCode);

      const $container = $this.closest(".js-product-order-box");

      ProductHelper.updateQuantityAndPrice(
        e,
        $container,
        isProductPage,
        isOnlyChange,
        isTemplate,
        1,
        isAddToBasket
      );

      ProductHelper.updateDiscountDisplay($container);
    });

    $("[name=quantity], [name=orderunitid]").on("keydown", (e) => {
      if (e.keyCode === 13) {
        e.target.blur();
      }
    });
  }

  static initRelatedProducts() {
    const relatedProductsEl = document.querySelector(".js-related-products-async") as HTMLElement | null;

    if (!relatedProductsEl) {
      return;
    }

    const action = relatedProductsEl.dataset.action;

    if (typeof action === "undefined") {
      return;
    }

    // preventOverlay is not standard axios but javascript's ability to add any property to an object
    axios.get(action, {
      timeout: 60000,
      preventOverlay: true
    } as AxiosRequestConfig)
      .then((response: AxiosResponse) => response.data)
      .then(data => {
        const $relatedProducts = $(relatedProductsEl);
        $relatedProducts.html(data.viewRelatedProducts);

        CustomSwiper.init();

        Products.initFavorites();
        Products.initOrderProduct();
        Products.initChangeProduct();
        Products.initPriceRequest();

        const failMessage: string = Multilang.getTranslation("productoverview.message.warning.stock.indication", "De voorraad per product is een indicatie.");
        Products.loadStock(CustomHelper.getListItemCodeList(), false, failMessage);
      })
      .catch((error: AxiosError) => {
        console.error(error);
      });
  }

  static initOrderProduct() {
    const isProductPage = 1;
    const isOnlyChange = 0;
    const isChangeOfOrderUnit = 0;
    const isAddToBasket = 1;

    let isTemplate = 0;

    let $container = $("#product-overview");
    if ($container.length === 0) {
      $container = $("#product-detail");
    }

    $container.off("submit", ".js-order-product");
    $container.on("submit", ".js-order-product", (e) => {
      const $form = $(e.target);

      isTemplate = $("[name=istemplate]").val() === "true" ? 1 : 0;

      ProductHelper.updateQuantityAndPrice(
        e,
        $form,
        isProductPage,
        isOnlyChange,
        isTemplate,
        isChangeOfOrderUnit,
        isAddToBasket
      );
    });
  }

  static initMobileFilterToggle() {
    $("body").on("click", ".js-mobile-product-filter-activator", (e) => {
      e.preventDefault();
      eventBus.emit("mobile-product-filter-activate");
    });
  }

  static initMobileNavToggle() {
    $("body").on("click", ".js-category-nav-trigger", (e) => {
      e.preventDefault();
      $(".js-category-nav-activator").trigger("click");
    });
  }

  static initPriceRequest() {
    const $body = $("body");

    $body.off("click", ".js-modal-price-request-submit");
    $body.on("click", ".js-modal-price-request-submit", (e) => {
      e.preventDefault();

      const $this = $(e.currentTarget);
      const $parent = $this.closest(".js-modal-price-request");
      const $orderQuantity = $parent.find("[name='modal-quantity']");
      const $orderUnit = $parent.find("[name='modal-order-unit'] option:selected");

      let orderQuantity = $orderQuantity.val() as string;
      orderQuantity = orderQuantity.replace(",", ".");

      if (orderQuantity.length === 0 || isNaN(parseInt(orderQuantity))) {
        $orderQuantity.parent().addClass("has-error");
        $orderQuantity.trigger("focus");

        $(".js-modal-price-request-quantity-warning").removeClass("d-none");

        return;
      } else {
        $orderQuantity.parent().removeClass("has-error");
        $(".js-modal-price-request-quantity-warning").addClass("d-none");
      }

      const q = $.ajax({
        type: "GET",
        url: $this.prop("href"),
        data: {
          orderQuantity: $orderQuantity.val(),
          orderUnitName: $orderUnit.text()
        }
      });

      q.done((data) => {
        if (data.isSuccess) {
          toastr.success(data.message);
        }
      });

      q.fail((xhr, textStatus, errorThrown) => {
        toastr.error(errorThrown);
      });
    });
  }

  static initLogDownload() {
    const $body = $("body");

    $body.off("click", ".js-download");
    $body.on("click", ".js-download", (e) => {
      const $this = $(e.target);
      const id = $this.data("id");
      const url = $this.attr("href") as string;
      Products.postLogDownload(id, url);
    });
  }

  static initScannerEvents() {
    const productDetailSourceEl = document.querySelector(".js-product-detail--source") as HTMLInputElement | null;

    if (productDetailSourceEl && productDetailSourceEl.value === "scanner") {
      DataLayerHelper.trackCustomEvent("search_scan_scan_succeeded");
      return;
    }

    const productOverviewSourceEl = document.querySelector(".js-product-overview--source") as HTMLInputElement | null;
    const productOverviewProductCountEl = document.querySelector(".js-product-overview--product-count") as HTMLInputElement | null;

    if (productOverviewSourceEl && productOverviewSourceEl.value === "scanner") {
      if (productOverviewProductCountEl && productOverviewProductCountEl.value === "0") {
        DataLayerHelper.trackCustomEvent("search_scan_scan_failed");
        return;
      }
    }
  }

  static initStockLoad() {
    // @ts-ignore
    if (Global.IsAuthenticated === false) {
      return;
    }

    Multilang.load();

    // detail page
    const productCode = $("[data-detail-code]").data("detail-code");

    if (typeof (productCode) !== "undefined") {
      const failMessage = Multilang.getTranslation("productdetail.message.warning.stock.indication", "De voorraad is een indicatie.");
      Products.loadStock([productCode], true, failMessage);

      return;
    }

    // overview page
    const failMessage: string = Multilang.getTranslation("productoverview.message.warning.stock.indication", "De voorraad per product is een indicatie.");
    Products.loadStock(CustomHelper.getListItemCodeList(), false, failMessage);
  }

  static loadOverview(url: string, isPush: boolean) {
    Multilang.load();

    console.log("🚀 loading overview", url);

    CustomHelper.startLoad();

    $.get(url, (data) => {
      const $full = $(data);
      const content = $full.find("#ajax-container");
      $("#ajax-container").replaceWith(content);

      Products.initFilters();
      Products.initFavorites();
      Products.initPageSize();
      Products.initOrderProduct();
      Products.initChangeProduct();
      Products.initPriceRequest();

      const productCodeList = CustomHelper.getListItemCodeList();

      const failMessage = Multilang.getTranslation("productoverview.message.warning.stock.indication", "De voorraad per product is een indicatie.");
      Products.loadStock(productCodeList, false, failMessage);

      CustomHelper.stopLoad();
      CustomHelper.smoothScrollTo("#ajax-container");

      const title = $full.filter("title").text();
      document.title = title;

      if (isPush) {
        history.pushState(null, title, url);
      }

      Products.filterItemClicked = false;
    });
  }

  static loadStock(productCodeList: string[], forceUpdate: boolean, failMessage: string) {
    if (productCodeList === null || productCodeList.length === 0) {
      return;
    }

    for (let i = 0; i < productCodeList.length; i++) {
      $(".js-product-stock-loader-" + productCodeList[i]).removeClass("d-none");
    }

    const q = $.ajax({
      type: "get",
      url: "/api/products/stock",
      data: {
        productCodeList: productCodeList,
        forceUpdate: forceUpdate
      },
      dataType: "json",
      traditional: true
    });

    q.done((productStockList) => {
      for (let i = 0; i < productCodeList.length; i++) {
        $(".js-product-stock-loader-" + productCodeList[i]).addClass("d-none");
      }

      if (typeof productStockList === "undefined") {
        return;
      }

      for (let i = 0; i < productStockList.length; i++) {
        const productStock = productStockList[i];

        ProductHelper.updateStockIndicator(productStock);

        const $productStockCountSpan = $("span.js-product-stock-count-" + productStock.productCode);
        $productStockCountSpan.text(productStock.stockCount);
        $productStockCountSpan.removeClass("d-none");

        const $productStockCountInput = $("input.js-product-stock-count-" + productStock.productCode);
        $productStockCountInput.val(productStock.stockCount);
      }
    });

    q.fail((xhr, textStatus, errorThrown) => {
      if (xhr.status === 500) {
        toastr["error"](failMessage);
      }

      if (xhr.status === 503) {
        toastr["warning"](failMessage);
      }

      for (let i = 0; i < productCodeList.length; i++) {
        $("span.js-product-stock-count-" + productCodeList[i]).removeClass("d-none"); // show fallback (data from DB)
        $(".js-product-stock-loader-" + productCodeList[i]).addClass("d-none");
      }
    });
  }

  static postFavorite(productId: number, isFavorite: boolean, dataLayerProduct: NatchOsDataLayerProduct | null) {
    const postData = {
      productId: productId,
      isFavorite: isFavorite
    };

    const q = $.ajax({
      type: "POST",
      url: "/api/products/setfavorite",
      data: JSON.stringify(postData),
      contentType: "application/json; charset=utf-8"
    });

    q.done((data) => {
      console.log("Product #" + productId + " is set to favorite " + isFavorite);

      if (dataLayerProduct) {
        const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
        natchGtm.trackAddToFavorites(dataLayerProduct);
      }
    });

    q.fail((xhr, textStatus, errorThrown) => {
      toastr["error"]("Could not set favorite.");
    });
  }

  static postProductNumber(productId: number, productNumber: string) {
    Multilang.load();

    const postData = {
      productId: productId,
      productNumber: productNumber
    };

    axios
      .post("/api/products/set-product-number", postData)
      .then((response: AxiosResponse) => response.data)
      .then(_ => {
        console.log(`Product #${productId} is set to product number ${productNumber}`);
        DataLayerHelper.trackCustomEvent("product_number_change", productNumber);
      })
      .catch((error: AxiosError) => {
        console.error(error);

        toastr["error"](Multilang.getTranslation("ChangeProductNumber.message.exception", "Er trad een fout op!"));
      });
  }

  static postLogDownload(productId: number, url: string) {
    const postData = {
      productId: productId,
      url: url
    };

    const q = $.ajax({
      type: "POST",
      url: "/api/products/logdownload",
      data: JSON.stringify(postData),
      contentType: "application/json; charset=utf-8"
    });

    q.fail((xhr, textStatus, errorThrown) => {
      console.error(xhr);
    });
  }
}
